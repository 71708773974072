<template>
  <div class="tab-bar" >
    <div class="tab" :class="{active: tabIndex == i}" v-for="(tab, i) in tabArr" :key="i" @click="changeTab(tab, i)">
      <div class="icon">
        <img :src="homeIcon" alt="" v-if="tabIndex !== 0 && i == 0">
        <img :src="homeActiveIcon" alt="" v-if="i == 0 && tabIndex == 0">
        
        <img :src="orderIcon" alt="" v-if="tabIndex !== 1 && i == 1">
        <img :src="orderActiveIcon" alt="" v-if="i == 1 && tabIndex == 1">

        <img :src="taskIcon" alt="" v-if="tabIndex !== 2 && i == 2">
        <img :src="taskActiveIcon" alt="" v-if="i == 2 && tabIndex == 2">

        <img :src="teamIcon" alt="" v-if="tabIndex !== 3 && i == 3">
        <img :src="teamActiveIcon" alt="" v-if="i == 3 && tabIndex == 3">

        <img :src="mineIcon" alt="" v-if="tabIndex !== 4 && i == 4">
        <img :src="mineActiveIcon" alt="" v-if="i == 4 && tabIndex == 4">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tabbar',
  props:{

  },
  data() {
    return {
      homeIcon: require('../../assets/imgs/tab/tar1.png'),
      homeActiveIcon: require('../../assets/imgs/tab/tar1s.png'),
      orderIcon: require('../../assets/imgs/tab/tar2.png'),
      orderActiveIcon: require('../../assets/imgs/tab/tar2s.png'),
      taskIcon: require('../../assets/imgs/tab/tar3.png'),
      taskActiveIcon: require('../../assets/imgs/tab/tar3s.png'),
      teamIcon: require('../../assets/imgs/tab/tar4.png'),
      teamActiveIcon: require('../../assets/imgs/tab/tar4s.png'),
      mineIcon: require('../../assets/imgs/tab/tar5.png'),
      mineActiveIcon: require('../../assets/imgs/tab/tar5s.png'),
      tabIndex: 0,
      defaultIcon: '',
      tabRoute: ['index', 'order', 'task', 'kefu', 'mine'],
      tabArr: [
        {title: 'tab1', icon: 'home', path: '/index'},
        {title: 'tab2', icon: 'order', path: '/order'},
        {title: 'tab3', icon: 'task', path: '/task'},
        {title: 'tab4', icon: 'team', path: '/kefu'},
        {title: 'tab5', icon: 'mine', path: '/mine'},
      ]
    }
  },
  watch: {
    $route(val) {
      let name = val.name
      if (this.tabRoute.indexOf(name) > -1) {
        this.init()
      } else {
        this.tabIndex = -1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let name = this.$route.name
      switch(name) {
        case 'index': this.tabIndex = 0; break;
        case 'order': this.tabIndex = 1; break;
        case 'task': this.tabIndex = 2; break;
        case 'kefu': this.tabIndex = 3; break;
        case 'mine': this.tabIndex = 4; break;
        default: this.tabIndex = -1; break;
      }
    },
    changeTab(tab, i) {
      this.tabIndex = i
      console.log("tab.title")
      console.log(window.flutter)
      if(tab.title === 'tab4') {
        if(window.flutter !== undefined) {
          this.kefuUrl = "myapp://" + window.location.host + "/#/kefu"
          window.location.href = this.kefuUrl
          return;
        }
      }
      this.$router.push({
        path: tab.path
      })
    }
  }
}
</script>